/* this is to fix some of bootstrap color accessibility issues */
$light-msu-green: #265C50;
$body-bg: #F0F0F0;
$body-color: #212529;
$theme-colors: (
  "primary": $light-msu-green,
);
$link-color: $light-msu-green;
$breadcrumb-bg: white;

/* more changes via variables */
$font-size-base: 0.9rem;
$h1-font-size: 2.0rem;
$h2-font-size: 1.2rem;
$h3-font-size: 1.1rem;
/* this is useful to avoid react-axe false positives:
$enable-transitions: false;
*/

/* Bootstrap SCSS */
@import "../node_modules/bootstrap-scss/bootstrap.scss";

/* buttons */
.btn {
  margin: 0.2em;
}
/* xs button size */
.btn-group-xs > .btn, .btn.btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

/* nav bars */
.nav {
  display: inline-flex;
  background-color: #FFFFFA;
  border-radius: 1.5em;
  border-style: outset;
  border-color: #AAA;
  border-width: 1px;
  margin: 0.5em 0 0.5em 0;
  overflow: hidden;
  
  .nav-item {
    &:hover {
      background-color: #F5F5F0;
    }
    .nav-link {
      font-weight: bold;
      font-size: 110%;
      &.active {
        background-color: $light-msu-green;
        color: white;
      }
      &:focus {
        box-shadow: inset 0 0 0 0.2rem rgba($light-msu-green, 0.5);
        outline: none;
      }
    }
  }
  .nav-item:first-child {
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
    .nav-link {
      border-top-left-radius: 1.5em;
      border-bottom-left-radius: 1.5em;
    }
  }
  .nav-item:last-child {
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    .nav-link {
      border-top-right-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
    }
  }
}

/* focus highlight */
h1:focus, a:focus {
	box-shadow: 0 0 0 0.2rem rgba($light-msu-green, 0.5);
	outline: none;
}

/* breadcrumbs */
.breadcrumb {
  margin-bottom: 0.5rem;
  a {
  	text-decoration: underline;
  }
}
