/* Common application styles */

/* Variables */
$msu-green: #18453B;
$light-msu-green: #265C50;
$container-padding: 1rem;

/* HTML Elements */
body {
	background: #333;
}

main {
	background: #F0F0F0;
	padding: 0.5rem $container-padding $container-padding $container-padding;
}

section {
  background-color: #FBFBFB;
  margin: 1em 0 1em 0;
  padding: 1em;
  
  >p:last-child {
    margin-bottom: 0;
  }
	&.border {
	  padding: 1em;
	  border-color: #888 !important;
	  border-radius: 0.5em;
	}
}

form {
	fieldset {
		background-color: #FBFBFB;
		border: 1px solid #CCC;
		padding: 0 1rem 1rem;
		margin: 1em 0 1em 0;

		legend {
			width: auto;
			font-size: 1.3rem;
		}
	}
}

h1 {
	font-size: 2rem;
}
h2 {
	font-size: 1.3rem;
	text-transform: uppercase;
}

/* Header */
header {
	background: $msu-green;
	color: #FFF;
	padding: 0.5rem;
	display: grid;
	grid-template-columns: 2fr 3fr;
}
.msu-logo {
	grid-column-start: 1;
	grid-column-end: 3;
}
#icon-logo {
	height: 34px;
	path {
		fill: #FFF;
	}
}
.college-and-app-title {
	grid-column-start: 3;
	grid-column-end: 6;
}
.header-unitName,
.header-appName {
	font-size: 1.6rem;
}

/* Login */
#login {
	margin-bottom: 1rem;
}

/* Tables */
table.data {
  width: auto;
  background-color: white;
  border-radius: 12px;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  box-shadow: 3px 3px 4px #AAA;
  margin: 0.5em 5px 1em 0;
  
  thead>tr:first-child>th:first-child, tbody>tr:first-child>th:first-child {
    border-top-left-radius: 10px;
  }
  thead>tr:first-child>th:last-child, tbody>tr:first-child>td:last-child {
    border-top-right-radius: 10px;
  }
  thead~tbody>tr:first-child>td:last-child {
    border-top-right-radius: 0;
  }
  th {
    &:focus {
      box-shadow: inset 0 0 0 0.2rem rgba($light-msu-green, 0.5);
      outline: none;
    }
  }
  td.code {
    white-space: pre-wrap;
    font-size: 90%;
  }
  >tbody>tr {
    &:nth-of-type(2n+1) {
      background-color: #EFFAFE;
			&:hover {
				background-color: darken(#EFFAFE, 5%);
			}
    }
		&:nth-of-type(2n) {
      background-color: white;
			&:hover {
				background-color: darken(white, 5%);
			}
    }
    &.new {
			background-color: #FFF3CA;
			&:hover {
				background-color: darken(#FFF3CA, 5%);
			}
    }
    &.cancelled {
			background-color: #F8D7DA;
			&:hover {
				background-color: darken(#F8D7DA, 5%);
			}
    }
  }
}

/* Request List */
.request-list-top-forms {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

/* New Request */
#formIntro {
  border: 1px solid red;
  border-radius: 0.5em;
}

/* Footer */
footer {
	background: #333;
	padding: 1rem;
	grid-template-columns: repeat(12, 1fr);
	color: #FFF;
	a {
		color: #FFF;
		&:hover {
			color: #FFF;
		}
	}
}
.footer-container {
	display: grid;
	max-width: 1170px;
	margin: 0 auto;
}
#footer-wordmark {
	grid-column-start: 1;
	grid-column-end: 4;
}
#icon-wordmark {
	height: 50px;
	path {
		fill: #FFF;
	}
}
#footer-links-and-info {
	grid-column-start: 4;
	grid-column-end: 13;
}
#footer-links,
#footer-info {
	font-size: 0.8rem;
	ul {
		margin-bottom: 0;
		padding: 0;
		li {
			border-right: 1px solid #FFF;
			display: inline;
			padding: 0 5px;
			&:last-child {
				border: none;
			}
		}
	}
}
#footer-links {
	border-bottom: 1px dotted;
	padding-bottom: 2px;
}
#footer-info {
	padding-top: 2px;

	a {
		color: #81D300;
	}
}

/* Alerts */
.alert p:last-of-type {
	margin-bottom: 0;
}

/* Media Queries */

/* Header */
@media all and (max-width: 900px) {
	header {
		display: block;
		text-align: center;
	}
}
@media print {
	header {
		background: white;
		color: black;
	}
	#icon-logo {
		path {
			fill: $msu-green;
		}
	}
}

/* Footer */
@media all and (max-width: 1170px) {
	#footer-wordmark,
	#footer-links-and-info {
		grid-column-start: 1;
		grid-column-end: 13;
		text-align: center;
	}
	#footer-wordmark {
		grid-row: 2;
		margin-top: 2rem;
	}

	#footer-links-and-info {
		grid-row: 1;
	}
}
@media print {
	footer {
		color: black;
		a {
			color: $msu-green;
		}
	}
	#icon-wordmark {
		path {
			fill: $msu-green;
		}
	}
	#footer-info a {
		color: $msu-green;
	}
}

/* Headings */
@media all and (max-width: 1200px) {
	h1 {
	  text-align: center;
	}
}

/* Request List */
@media only screen and (max-width: 800px) {
	.request-list-top-forms {
		.form-inline {
			flex-direction: column;
			align-items: stretch;
		}
	}
}
